import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import * as ProjectStyles from "../components/project.module.css";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

export const query = graphql`
  query ($slug: String!) {
    sanityArtwork(slug: { current: { eq: $slug } }) {
      title
      description
      medium
      size
      slug {
        current
      }
      tag {
        tagName
      }
      img {
        asset {
          gatsbyImageData
          originalFilename
        }
      }
      _rawText
    }
  }
`;

const Project = ({ data }) => {
  
  const tags = data.sanityArtwork.tag[0].tagName;
  let isDrone = false;
  if(tags === 'drone') isDrone = true;

  const options = {
    settings: {
      overlayColor: "rgb(255,255,255,0.9)",
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      autoplaySpeed: 5000,
      transitionSpeed: 900,
    },
    buttons: {
      backgroundColor: "white",
      iconColor: "rgba(0,0,0 0.8)",
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
    caption: {
      captionColor: "blue",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
  };

  return (
    <Layout>
      <div className={ProjectStyles.desktop}>
        <div className={ProjectStyles.printPres}>
          <div className={ProjectStyles.description}>
            <div className={ProjectStyles.lineBreak}></div>
            
            <h2> {data.sanityArtwork.title}</h2>
            <div className={ProjectStyles.lineBreak}></div>
            <p> {data.sanityArtwork.description} </p>
            <div className={ProjectStyles.lineBreak}></div>
          

            <p className={ProjectStyles.details}>
              • Medium: {data.sanityArtwork.medium}{" "}
            </p>
            { isDrone ? (
                   <p className={ProjectStyles.details}>
                   • Duration: {data.sanityArtwork.size}{" "}
                 </p>         
                ) : (
                  <p className={ProjectStyles.details}>
                  • Image size: {data.sanityArtwork.size}{" "}
                </p> 
                )
              } 
            <br />
            <p>• Click on image to see more images.</p>
            <div className={ProjectStyles.lineBreak}></div>
            
          </div>

          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {data.sanityArtwork.img.map((image, index) => (     
                <GatsbyImage
                  className={ProjectStyles.galleryImages}
                  image={image.asset.gatsbyImageData}
                  alt={ 
                    image.asset.originalFilename.replace(/\.[^/.]+$/, "")
                   }
                />
              ))}
            </SRLWrapper>
          </SimpleReactLightbox>
          
        </div>
        </div>

        <div className={ProjectStyles.mobile}>
        <div className={ProjectStyles.printPres}>
        <div className={ProjectStyles.lineBreak}></div>
          <h3> {data.sanityArtwork.title}</h3>
        <div className={ProjectStyles.lineBreak}></div>

          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {data.sanityArtwork.img.map((image, index) => (     
                <GatsbyImage
                  className={ProjectStyles.galleryImages}
                  image={image.asset.gatsbyImageData}
                  alt={ 
                    image.asset.originalFilename.replace(/\.[^/.]+$/, "")
                   }
                />
              ))}
            </SRLWrapper>
          </SimpleReactLightbox>
          <div className={ProjectStyles.description}>
            <div className={ProjectStyles.lineBreak}></div>
            <p> {data.sanityArtwork.description} </p>
            <div className={ProjectStyles.lineBreak}></div>
            
            <p className={ProjectStyles.details}>
              • Medium: {data.sanityArtwork.medium}{" "}
            </p>
            { isDrone ? (
                   <p className={ProjectStyles.details}>
                   • Duration: {data.sanityArtwork.size}{" "}
                 </p>         
                ) : (
                  <p className={ProjectStyles.details}>
                  • Image size: {data.sanityArtwork.size}{" "}
                </p> 
                )
              } 
            <p className={ProjectStyles.details}>
                • Click on image to see more images.
              </p>
            <div className={ProjectStyles.lineBreak}></div>
            
          </div>
        </div>
        </div>

        <div className={ProjectStyles.textPres}>
          <h2> Presentation</h2>
          <BlockContent blocks={data.sanityArtwork._rawText} />
        </div>

      
    </Layout>
  );
};

export default Project;
